<template>
  <div class="about pa-6">
    <h1>About {{$store.state.appTitle}}</h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut, explicabo, nobis! Beatae culpa deleniti
      fugit harum provident repudiandae suscipit. Architecto cum, ducimus explicabo inventore pariatur quaerat
      recusandae tempore velit vitae.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut, explicabo, nobis! Beatae culpa deleniti
      fugit harum provident repudiandae suscipit. Architecto cum, ducimus explicabo inventore pariatur quaerat
      recusandae tempore velit vitae.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut, explicabo, nobis! Beatae culpa deleniti
      fugit harum provident repudiandae suscipit. Architecto cum, ducimus explicabo inventore pariatur quaerat
      recusandae tempore velit vitae.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut, explicabo, nobis! Beatae culpa deleniti
      fugit harum provident repudiandae suscipit. Architecto cum, ducimus explicabo inventore pariatur quaerat
      recusandae tempore velit vitae.</p>
  </div>
</template>

<script>
export default {
  name: "About",

}
</script>
